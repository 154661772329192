import { Image, StyleSheet, View } from 'react-native';
import React from 'react';
import Input from '../common/Input';
import { ActivityIndicator } from 'react-native';
import {
    FontAwesome,
    FontAwesome5,
    MaterialCommunityIcons,
    MaterialIcons,
    Octicons,
} from '@expo/vector-icons';

const SearchBar = ({ onQuery, isQuerying, onBlur, onFocus, onSubmit }) => {
    return (
        <View style={{ flex: 1, alignItems: 'center' }}>
            <Input
                autoFocus={true}
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={onQuery}
                onKeyPress={onSubmit}
                containerstyle={[styles.inputContainer]}
                placeholder="Search By Patient ID"
                clear
                required
                labelNumber={4}
                leftIcon={
                    <View style={{ paddingLeft: 14 }}>
                        <FontAwesome
                            name="Search"
                            color="#10206C"
                            backgroundColor="#FFFFFF"
                            size={25}
                        />
                    </View>
                }
            />
            {isQuerying && <ActivityIndicator />}
        </View>
    );
};

const styles = StyleSheet.create({
    inputContainer: {
        marginLeft: 0,
        borderWidth: 2,
        borderColor: '#75256c',
        minWidth: 700,
        borderRadius: 20,
        flex: 1,
        borderBottomWidth: 2,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    noBorders: {
        borderBottomWidth: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    img: {
        width: 20,
        height: 20,
    },
});

export default SearchBar;
