import React, { Component } from 'react';
import { Auth, Cache } from 'aws-amplify';
import { Authenticator, withOAuth } from 'aws-amplify-react';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';
import {
    Text,
    View,
    KeyboardAvoidingView,
    StyleSheet,
    TouchableOpacity,
    Image,
    ActivityIndicator,
    AsyncStorage,
    ImageBackground,
} from 'react-native';

import { connect } from 'react-redux';

import { Feather } from '@expo/vector-icons';
import { Redirect } from 'react-router-dom';
import { Button, Box } from '../components/common';
import VyaireLogo from '../components/VyaireLogo';
import Flex from '../components/common/Flex';
import { Colors } from '../theme/Index';
import { IDENTITY_PROVIDER } from '@env';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            customState: null,
        };
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser().then((user) => console.log(user));

        const params = window.location.pathname;
        const search = window.location.search;
    }

    handleStateChange = (state, data) => {
        if (state === 'signedIn') {
            // console.log(state, data);
            this.props.onUserSignIn();
        }
    };

    render() {
        const { error } = this.props;

        const { user } = this.state;

        if (user && localStorage.getItem('SFDCData') == null) {
            return <Redirect to="/" />;
        }

        if (this.props.loading == true) {
            return (
                <View
                    style={{
                        flex: 1,
                        flexBasis: 'auto',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <ActivityIndicator />
                </View>
            );
        }

        return (
            <View
                style={{
                    flex: 1,
                    flexBasis: 'auto',
                }}>
                <ImageBackground
                    source={require('../../assets/icons/BGNEW2.jpg')}
                    style={{ width: '100%', height: '100%' }}>
                    <View
                        style={{
                            flex: 1,
                            flexBasis: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: hp('100%'),
                            paddingHorizontal: 70,
                            paddingVertical: 40,
                        }}>
                        <Authenticator
                            hideDefault={true}
                            onStateChange={this.handleStateChange}>
                            <View
                                style={{
                                    flex: 1,
                                    flexBasis: 'auto',
                                    shadowColor: '#000',
                                }}>
                                <View style={styles.containerView}>
                                    <View style={styles.loginScreenContainer}>
                                        <View style={styles.loginFormView}>
                                            <Flex
                                                style={styles.logoContainer}
                                                alignCenter>
                                                <View>
                                                    <Image
                                                        resizeMode="contain"
                                                        style={{
                                                            width: 250,
                                                            height: 150,
                                                        }}
                                                        source={require('../../assets/logo/IRISNEW2.png')}
                                                    />
                                                    {/* <VyaireLogo width={125} /> */}
                                                </View>
                                            </Flex>
                                            <Flex
                                                style={styles.logoContainer}
                                                alignCenter>
                                                <View>
                                                    <Image
                                                        resizeMode="contain"
                                                        style={{
                                                            width: 250,
                                                            height: 40,
                                                        }}
                                                        source={require('../../assets/icons/logo_new1.png')}
                                                    />
                                                    {/* <VyaireLogo width={125} /> */}
                                                </View>
                                            </Flex>
                                            {error && (
                                                <View style={styles.errorContainer}>
                                                    <Feather
                                                        style={{
                                                            marginLeft: 25,
                                                            marginRight: 15,
                                                        }}
                                                        name="x-circle"
                                                        size={16}
                                                        color="grey"
                                                    />
                                                    <Text style={styles.errorMessage}>
                                                        {error}
                                                    </Text>
                                                </View>
                                            )}
                                            <Box>
                                                <Button
                                                    titleStyle={{
                                                        fontWeight: '500',
                                                    }}
                                                    mr="0px"
                                                    onPress={() =>
                                                        Auth.federatedSignIn({
                                                            // @ts-ignore
                                                            provider: IDENTITY_PROVIDER,
                                                        })
                                                    }
                                                    title="Sign In"
                                                />
                                            </Box>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Authenticator>
                    </View>
                </ImageBackground>
            </View>
        );
    }
}

export default withOAuth(Login);

const styles = StyleSheet.create({
    containerView: {
        flex: 1,
        flexBasis: 'auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 50,
    },
    logoContainer: {
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    insightLogo: {
        width: 170,
        height: 150,
    },
    errorContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#F9CFC7',
        paddingVertical: 15,
        marginHorizontal: 15,
        borderRadius: 5,
    },
    errorMessage: {
        color: '#333333',
        fontWeight: '500',
        fontSize: 16,
    },
    loginScreenContainer: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
        flex: 1,
        flexBasis: 'auto',
        minWidth: 425,
        minHeight: 325,
        paddingHorizontal: 75,
        backgroundColor: '#FFF',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },

    loginFormView: {
        flex: 1,
        flexBasis: 'auto',
    },
    loginFormTextInput: {
        height: 43,
        fontSize: 14,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#eaeaea',
        backgroundColor: '#fafafa',
        paddingLeft: 10,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 15,
        marginBottom: 5,
    },
    loginButton: {
        backgroundColor: '#0756B8',
        borderRadius: 5,
        minHeight: 45,
        marginTop: 25,
        marginBottom: 25,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 124,
    },
    loginButtonTitle: {
        paddingHorizontal: 5,
        paddingVertical: 10,
        textAlign: 'center',
        color: '#FFFFFF',
        fontSize: 18,
    },
    fbLoginButton: {
        height: 45,
        marginTop: 10,
        backgroundColor: 'transparent',
    },
    mText: {
        fontSize: 40,
        fontWeight: '400',
        paddingTop: 18,
        marginLeft: 5,
        color: Colors.lightBlue,
    },
});
