import React, { Component, useState } from 'react';
import { ActivityIndicator, Easing, Image, View, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-web';
import debounce from 'debounce';
import { connect } from 'react-redux';
import shallowCompare from 'react-addons-shallow-compare'; // ES6
import { Container, Flex, Box, Text, Button } from '../components/common';
import { Link } from '../navigation/router';
import { SearchBar, SearchResults } from '../components/search';
import Multiselect from 'multiselect-react-dropdown';
import {
    FontAwesome,
    FontAwesome5,
    MaterialCommunityIcons,
    MaterialIcons,
    Octicons,
} from '@expo/vector-icons';
import {
    searchPatient,
    fetchVGStudyReport,
    fetchReportStatus,
} from '../appRedux/actions';
import { Colors } from '../theme/Index';

import { MenuContext } from '../Root';
import { textSpanIntersection } from 'typescript';

const userId = localStorage.getItem('userId');

export class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPatients: [],
            searchResult: [],
            pavogPermissions: [],
            bvstudyPermissions: [],
        };
    }
    componentDidMount() {
        // if (!this.props.isToggled) this.props.toggleMenu(true);
        let irisSecurityPermissions = this.props.userRolePermissions || [];
        console.log('irisSecurityPermissions--', irisSecurityPermissions);
        let hasPavog = irisSecurityPermissions.some(
            (el) => el.PermissionName === 'App:PaVoG'
        );

        let hasBVStudy = irisSecurityPermissions.some(
            (el) => el.PermissionName === 'App:BVStudy'
        );

        let pavogObj = hasPavog
            ? irisSecurityPermissions.filter((el) => el.PermissionName === 'App:PaVoG')
            : [];
        let pavogPermissions =
            pavogObj.length > 0 ? pavogObj[0]['PermissionActionNames'] : [];

        let bvstudyObj = hasBVStudy
            ? irisSecurityPermissions.filter((el) => el.PermissionName === 'App:BVStudy')
            : [];
        let bvstudyPermissions =
            bvstudyObj.length > 0 ? bvstudyObj[0]['PermissionActionNames'] : [];

        this.setState({
            pavogPermissions: pavogPermissions,
            bvstudyPermissions: bvstudyPermissions,
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.patientSearchList != this.props.patientSearchList) {
            this.setState({ searchResult: newProps.patientSearchList });
        }
    }

    onSubmit = (event) => {
        var jsonData = {
            PatientIds: this.state.selectedPatients,
            ForceGenerate: false,
        };
        let study = this.props.location.pathname.toLowerCase();
        var postdata = {
            Data: jsonData,
            path: this.props.location.pathname,
            history: this.props.history,
            hasGeneratePermissions: study.includes('pavog')
                ? this.state.pavogPermissions.includes('GenerateDataMonitoringReport')
                : this.state.bvstudyPermissions.includes('GenerateDataMonitoringReport'),
        };
        this.setState({ searchResult: [] }, () =>
            this.props.fetchVGStudyReport(postdata)
        );
    };

    handleOnSearch = debounce((e) => {
        console.log('e u here--', e.trim());
        console.log('loc', this.props.location.pathname);
        if (e.trim().length >= 0) {
            var postdata = {
                json: { prefix: e.trim() },
                path: this.props.location.pathname,
            };
            console.log('here--', postdata);
            this.props.searchPatient(postdata);
        }
    }, 400);

    addSelected = (arrayValues) => {
        this.setState({ selectedPatients: arrayValues });
    };
    removeSelected = (arrayValues) => {
        this.setState({ selectedPatients: arrayValues });
    };

    render() {
        const { searchResult } = this.state;
        const study = this.props.location.pathname.toLowerCase().includes('pavog')
            ? 'PaVoG'
            : 'BVStudy';
        if (this.props.fetchingVGReport)
            return (
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="650px">
                    <ActivityIndicator size="large" />
                </Box>
            );
        return (
            <Container full fullVertical>
                <View style={{ width: '100%', alignItems: 'center' }}>
                    <Text
                        style={{
                            fontWeight: '600',
                            fontSize: '35px',
                            color: Colors.irisPurple,
                        }}>
                        {study}
                    </Text>
                </View>
                <Flex
                    alignCenter
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        marginTop: '20vh',
                        bottom: 0,
                    }}>
                    <>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                width: '60%',
                            }}>
                            {/* <SearchBar
                                onFocus={() => this.setState({ isFocused: true })}
                                onBlur={this.handleOnBlur}
                                onSubmit={this.onSubmit}
                                onQuery={this.handleOnChange}
                                isQuerying={this.props.fetching}/> */}
                            <MaterialCommunityIcons
                                name="account-search"
                                color={Colors.irisPurple}
                                backgroundColor="#FFFFFF"
                                size={25}
                            />

                            <Multiselect
                                isObject={false}
                                placeholder="Search by Patiend Id"
                                onKeyPressFn={function noRefCheck() {}}
                                onRemove={this.removeSelected}
                                onSearch={this.handleOnSearch}
                                onSelect={this.addSelected}
                                closeIcon={'cancel'}
                                selectionLimit={5}
                                style={{
                                    searchBox: {
                                        'border-color': Colors.irirsPurple,
                                        'border-radius': '10px',
                                    },
                                    option: {
                                        color: Colors.royalBlue,
                                        background: Colors.twentyPurple,
                                        borderBottom: '0.5px solid grey',
                                    },
                                    chips: {
                                        color: Colors.royalBlue,
                                        background: Colors.twentyPurple,
                                    },
                                }}
                                options={searchResult}
                                showCheckbox
                                closeOnSelect={false}
                            />
                            {this.state.selectedPatients.length > 0 && (
                                <Button
                                    style={{
                                        height: '90%',
                                        borderRadius: '15px',
                                        padding: '10px',
                                    }}
                                    onPress={this.onSubmit}
                                    title="Go"
                                />
                            )}
                        </View>
                        {this.props.fetching && <ActivityIndicator />}
                    </>
                </Flex>
            </Container>
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
}

class SearchPage extends Component {
    render() {
        return (
            <MenuContext.Consumer>
                {({ isToggled, toggleMenu }) => {
                    return (
                        <Page
                            {...this.props}
                            toggleMenu={toggleMenu}
                            isToggled={isToggled}
                        />
                    );
                }}
            </MenuContext.Consumer>
        );
    }
}

const styles = StyleSheet.create({
    linkContainer: {
        flex: 1,
        position: 'relative',
        right: '-580px',
        marginBottom: 16,
        width: '20%',
    },
    linkText: {
        textAlign: 'right',
        color: '#4195C7',
        paddingHorizontal: 1,
        borderBottomWidth: 1,
        borderBottomColor: '#4195C7',
        borderBottomStyle: 'solid',
    },
});

//from redux store
const mapStateToProps = ({ patient, auth }) => {
    const { fetching, fetchingVGReport, patientSearchList, patientsVgStudy } = patient;
    const { userRolePermissions } = auth;
    return {
        patientSearchList,
        patientsVgStudy,
        fetching,
        fetchingVGReport,
        userRolePermissions,
    };
};

export default connect(mapStateToProps, {
    searchPatient,
    fetchVGStudyReport,
    fetchReportStatus,
})(SearchPage);
