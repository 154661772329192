import { exp } from 'react-native-reanimated';

//General
export const SUCCESS_BGCOLOR = '#27ae60';
export const FAILED_BGCOLOR = '#c0392b';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

// auth
export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_FAILED = 'AUTH_USER_FAILED';
export const LOGGED_IN_STATUS_CHANGED = 'LOGGED_IN_STATUS_CHANGED';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_USER = 'LOGOUT_USER';
export const USER_ROLE_PERMISSIONS = 'USER_ROLE_PERMISSIONS';

//notification
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const QUEUE_REMOVE = 'QUEUE_REMOVE';

// search patient
export const SEARCH_PATIENTS = 'SEARCH_PATIENTS';
export const SEARCH_PATIENTS_SUCCESS = 'SEARCH_PATIENTS_SUCCESS';
export const SEARCH_PATIENTS_FAIL = 'SEARCH_PATIENTS_FAIL';
export const FETCH_VG_STUDY = 'FETCH_VG_STUDY';
export const FETCH_VG_STUDY_SUCCESS = 'FETCH_VG_STUDY_SUCCESS';
export const REFRESH_VG_REPORT_STATUS = 'REFRESH_VG_REPORT_STATUS';
export const REFRESH_VG_REPORT_STATUS_SUCCESS = 'REFRESH_VG_REPORT_STATUS_SUCCESS';
export const FORCE_REFRESH_VG_STUDY = 'FORCE_REFRESH_VG_STUDY';
export const FORCE_REFRESH_VG_STUDY_SUCCESS = 'FORCE_REFRESH_VG_STUDY_SUCCESS';
