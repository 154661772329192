import React, { Component, useState } from 'react';
import { ActivityIndicator, Easing, Image, View, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-web';
import debounce from 'debounce';
import { connect } from 'react-redux';
import { Box, Container, Flex, Text, Button } from '../components/common';
import { Link } from '../navigation/router';
import { SearchBar, SearchResults } from '../components/search';
import Multiselect from 'multiselect-react-dropdown';
import {
    FontAwesome,
    FontAwesome5,
    MaterialCommunityIcons,
    MaterialIcons,
    Ionicons,
} from '@expo/vector-icons';
import {
    searchPatient,
    fetchVGStudyReport,
    fetchReportStatus,
    forceRefreshVGStudyReport,
} from '../appRedux/actions';
import { Colors } from '../theme/Index';
import { ajaxPostRequest, endpoints } from '../appRedux/sagas/config';
import { ReportStatusType } from '../constants/WorkflowEnums';
import axios from 'axios';
import { MenuContext } from '../Root';
import { textSpanIntersection } from 'typescript';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import { Auth } from 'aws-amplify';

const userId = localStorage.getItem('userId');

export class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerdata: [],
            searchResult: [],
            isFocused: false,
            TypeaheadKeyword: '',
            pavogPermissions: [],
        };
    }

    componentDidMount() {
        // if (!this.props.isToggled) this.props.toggleMenu(true);
        let irisSecurityPermissions = this.props.userRolePermissions;
        let hasPavog = irisSecurityPermissions.some(
            (el) => el.PermissionName === 'App:PaVoG'
        );
        let pavogObj = hasPavog
            ? irisSecurityPermissions.filter((el) => el.PermissionName === 'App:PaVoG')
            : [];
        let pavogPermissions = pavogObj[0]['PermissionActionNames'];

        let hasBVStudy = irisSecurityPermissions.some(
            (el) => el.PermissionName === 'App:BVStudy'
        );
        let bvstudyObj = hasBVStudy
            ? irisSecurityPermissions.filter((el) => el.PermissionName === 'App:BVStudy')
            : [];
        let bvstudyPermissions = bvstudyObj[0]['PermissionActionNames'];

        this.setState({
            pavogPermissions: pavogPermissions,
            bvstudyPermissions: bvstudyPermissions,
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.patientSearchList != this.props.patientSearchList) {
            this.setState({ searchResult: newProps.patientSearchList });
        }
    }

    refreshReportStatus = () => {
        //calls refreshReportStatus API
        let patientsVgStudy = this.props.patientsVgStudy || [];
        let toFetchReports = patientsVgStudy.filter((report) => report.ReportStatus != 4);
        let patientList = [];
        if (toFetchReports.length > 0) {
            toFetchReports.forEach((item) => patientList.push(item.PatientId));
        }
        if (patientList.length > 0) {
            let postdata = { json: patientList, path: this.props.location.pathname };
            this.props.fetchReportStatus(postdata);
        }
    };

    refreshSinglePatientReport = (patientId) => {
        //calls fetchVgStudyReport API
        var postdata = {
            json: { PatientIds: [patientId], ForceGenerate: true },
            path: this.props.location.pathname,
        };
        this.props.forceRefreshVGStudyReport(postdata);
    };

    downloadFile = (id, S3objectKey) => {
        console.log('S3objectKey', { S3ObjectReportKey: S3objectKey });
        this.setState({ [id]: true });

        let path = this.props.location.pathname.toLowerCase();
        let downloadURl = path.includes('pavog')
            ? endpoints.generatePreSignUrl
            : endpoints.generatePreSignUrlBv;
        ajaxPostRequest(downloadURl, {
            S3ObjectReportKey: S3objectKey,
        }).then((url) => {
            this.setState({ [id]: false });
            window.open(url, '_blank');
        });

        // .then((presignurl) => {
        //     // var link=document.createElement('a');
        //     // link.href = presignurl;
        //     // link.download = presignurl.substr(presignurl.lastIndexOf('/') + 1);
        //     // link.click();

        //     // window.open(presignurl, '_blank');

        //     html2canvas(presignurl)
        //     .then((canvas) => {
        //         const imgData = canvas.toDataURL('image/png');
        //         const pdf = new jsPDF();
        //         pdf.addImage(imgData, 'JPEG', 0, 0);
        //         pdf.save(`xx.pdf`);
        //     })
        // });
    };

    formatDate = (datetoformat) => {
        var utcdate = datetoformat.replace('T', ' ');
        const localDate = moment.utc(utcdate).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm');
        return requestDate;
    };

    render() {
        const { patientsVgStudy = [] } = this.props;
        const study = this.props.location.pathname.toLowerCase().includes('pavog')
            ? 'PaVoG'
            : 'BVStudy';
        const { pavogPermissions = [], bvstudyPermissions = [] } = this.state;
        let hasDownloadAccess =
            study == 'PaVoG'
                ? pavogPermissions.includes('DownloadDataMonitoringReport')
                : bvstudyPermissions.includes('DownloadDataMonitoringReport');
        let hasRegenerateAccess =
            study == 'PaVoG'
                ? pavogPermissions.includes('ReGenerateDataMonitoringReport')
                : bvstudyPermissions.includes('ReGenerateDataMonitoringReport');

        let isAnyInprogress = patientsVgStudy.some(
            (report) => report.ReportStatus == 1 || report.ReportStatus == 2
        );

        return (
            <Container full fullVertical>
                <View style={{ width: '100%', alignItems: 'center' }}>
                    <Text
                        style={{
                            fontWeight: '600',
                            fontSize: '35px',
                            color: Colors.irisPurple,
                        }}>
                        {study}
                    </Text>
                </View>
                {this.props.fetchingVGReport || this.props.fetchingReportStatus ? (
                    <Box
                        display="flex"
                        flex={1}
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="500px">
                        <ActivityIndicator size="large" />
                    </Box>
                ) : (
                    <>
                        <View style={{ width: '100%', alignItems: 'flex-end' }}>
                            {/* <Button style={{background:Colors.irisPurple,marginRight:'10px',borderRadius:'10px'}} onPress={this.downloadFile} title="Refresh" />           */}
                            {isAnyInprogress && (
                                <Ionicons
                                    name="md-refresh-circle"
                                    color={Colors.irisPurple}
                                    backgroundColor="#FFFFFF"
                                    size={50}
                                    style={{ marginRight: '10px' }}
                                    onPress={this.refreshReportStatus}
                                />
                            )}
                        </View>
                        <Flex
                            alignCenter
                            style={{
                                marginTop: '5vh',
                                bottom: 0,
                                flex: 1,
                                flexBasis: 'auto',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                flexWrap: 'wrap',
                            }}>
                            {patientsVgStudy.length > 0 &&
                                patientsVgStudy.map((data, index) => (
                                    <View
                                        style={{
                                            alignItems: 'center',
                                            flex: 1,
                                            flexDirection: 'column',
                                            maxWidth: '12%',
                                            border: '1px solid #10206c',
                                            borderRadius: '7px 7px 0px 0px',
                                        }}>
                                        <View
                                            style={{
                                                width: '100%',
                                                alignItems: 'center',
                                                backgroundColor: 'aliceblue',
                                                borderBottom: '1px solid #10206c',
                                                borderRadius: '7px 7px 0px 0px',
                                            }}>
                                            <Text style={{ fontWeight: '600' }}>
                                                Patient ID : {data.PatientId}
                                            </Text>
                                        </View>

                                        {data.ReportStatus == 1 ||
                                        data.ReportStatus == 2 ? (
                                            <>
                                                {' '}
                                                <MaterialCommunityIcons
                                                    name="file-alert-outline"
                                                    color={Colors.irisPurple}
                                                    backgroundColor="#FFFFFF"
                                                    size={100}
                                                />
                                                <View
                                                    style={{
                                                        alignItems: 'center',
                                                        flex: 1,
                                                        flexDirection: 'row',
                                                        border: '1px solid #10206c',
                                                        borderRadius: '7px 7px 0px 0px',
                                                        width: '100%',
                                                    }}>
                                                    <Button
                                                        style={{
                                                            background: Colors.irisPurple,
                                                            width: '100%',
                                                        }}
                                                        title={
                                                            ReportStatusType[
                                                                data.ReportStatus
                                                            ]
                                                        }
                                                    />
                                                </View>
                                            </>
                                        ) : (
                                            <>
                                                <MaterialCommunityIcons
                                                    name="file-check-outline"
                                                    color={Colors.irisPurple}
                                                    backgroundColor="#FFFFFF"
                                                    size={100}
                                                />
                                                {this.state[data.PatientId] ? (
                                                    <ActivityIndicator />
                                                ) : (
                                                    <View
                                                        style={{
                                                            alignItems: 'center',
                                                            flex: 1,
                                                            flexDirection: 'row',
                                                            border: '1px solid #10206c',
                                                            borderRadius:
                                                                '7px 7px 0px 0px',
                                                            background: Colors.irisPurple,
                                                            borderRadius:
                                                                '7px 7px 0px 0px',
                                                            width: '100%',
                                                            justifyContent: 'center',
                                                        }}>
                                                        {hasDownloadAccess && (
                                                            <View
                                                                style={{
                                                                    width: hasRegenerateAccess
                                                                        ? '80%'
                                                                        : '100%',
                                                                    borderRight:
                                                                        hasRegenerateAccess
                                                                            ? '1px solid white'
                                                                            : '',
                                                                }}>
                                                                <Button
                                                                    style={{
                                                                        background:
                                                                            Colors.irisPurple,
                                                                    }}
                                                                    titleStyle={{
                                                                        fontSize: '10px',
                                                                    }}
                                                                    onPress={() =>
                                                                        this.downloadFile(
                                                                            data.PatientId,
                                                                            data.S3ObjectReportKey
                                                                        )
                                                                    }
                                                                    title={
                                                                        'Download' +
                                                                        '\n' +
                                                                        this.formatDate(
                                                                            data.ReportGeneratedOn
                                                                        )
                                                                    }
                                                                />
                                                            </View>
                                                        )}
                                                        {hasRegenerateAccess && (
                                                            <View
                                                                style={{ width: '20%' }}>
                                                                <FontAwesome
                                                                    name="refresh"
                                                                    color="white"
                                                                    background="#10206C"
                                                                    size={20}
                                                                    width="20%"
                                                                    style={{
                                                                        padding: '6px',
                                                                    }}
                                                                    onPress={() =>
                                                                        this.refreshSinglePatientReport(
                                                                            data.PatientId
                                                                        )
                                                                    }
                                                                />
                                                            </View>
                                                        )}
                                                    </View>
                                                )}
                                            </>
                                        )}
                                    </View>
                                ))}
                        </Flex>
                    </>
                )}
            </Container>
        );
    }
}

class VGStudyReportsPage extends Component {
    render() {
        return (
            <MenuContext.Consumer>
                {({ isToggled, toggleMenu }) => {
                    return (
                        <Page
                            {...this.props}
                            toggleMenu={toggleMenu}
                            isToggled={isToggled}
                        />
                    );
                }}
            </MenuContext.Consumer>
        );
    }
}

const styles = StyleSheet.create({
    linkContainer: {
        flex: 1,
        position: 'relative',
        right: '-580px',
        marginBottom: 16,
        width: '20%',
    },
    linkText: {
        textAlign: 'right',
        color: '#4195C7',
        paddingHorizontal: 1,
        borderBottomWidth: 1,
        borderBottomColor: '#4195C7',
        borderBottomStyle: 'solid',
    },
});

//from redux store
const mapStateToProps = ({ patient, auth }) => {
    const {
        fetching,
        fetchingReportStatus,
        fetchingVGReport,
        patientsVgStudy,
        reportStatuses,
    } = patient;
    const { userRolePermissions } = auth;
    return {
        patientsVgStudy,
        fetching,
        fetchingReportStatus,
        fetchingVGReport,
        reportStatuses,
        userRolePermissions,
    };
};

export default connect(mapStateToProps, {
    fetchVGStudyReport,
    fetchReportStatus,
    forceRefreshVGStudyReport,
})(VGStudyReportsPage);
