import React from 'react';
import { Text, Platform } from 'react-native';

export default (props) => (
    <Text
        {...props}
        style={[
            Platform.OS == 'web' ? { fontFamily: 'Poppins' } : { fontFamily: 'Arial' },
            props.style,
        ]}>
        {props.children}
    </Text>
);
