import React, { Component } from 'react';
import { View, Image, StyleSheet, Animated, Easing, ImageBackground } from 'react-native';
import { Link } from 'react-router-dom';
import { Box, Text, Input } from '../components/common/';
import * as pkg from '../../app.json';
import { connect } from 'react-redux';
const { stagger, spring } = Animated;

const ImageContainer = ({ source, style, path = '/', onClick, text }) => (
    <Box
        width={1 / 3}
        alignItems="center"
        justifyContent="center"
        mt={200}
        px={15}
        pb={50}>
        <Animated.View style={style}>
            <Link to={path} onClick={onClick}>
                <Image resizeMode="contain" style={styles.image} source={source} />
            </Link>
            <Text fontSize="xlarge" align="center">
                {text}
            </Text>
        </Animated.View>
    </Box>
);

class HomePage extends Component {
    constructor(props) {
        super(props);

        let anims = [];

        for (let i = 0; i < 3; i++) {
            const opacity = new Animated.Value(0);
            const translateY = opacity.interpolate({
                inputRange: [0, 1],
                outputRange: [200, 0],
            });

            anims.push({ posY: translateY, opacity });
        }

        this.state = {
            animVals: anims,
            hasBVStudy: false,
            hasPavog: false,
        };

        this.config = {
            delay: 75,
            duration: 300,
            toValue: 1,
            easing: Easing.ease,
        };

        this.anims = [
            spring(this.state.animVals[0].opacity, this.config),
            spring(this.state.animVals[1].opacity, this.config),
            spring(this.state.animVals[2].opacity, this.config),
        ];

        this.anim = stagger(100, this.anims);
    }

    componentDidMount() {
        this.anim.start();
        let windata =
            window.localStorage.getItem('irisPer') != null
                ? JSON.parse(window.localStorage.getItem('irisPer'))
                : [];
        let irisSecurityPermissions =
            this.props.userRolePermissions.length > 0
                ? this.props.userRolePermissions
                : windata;

        let hasPavog = irisSecurityPermissions.some(
            (el) => el.PermissionName === 'App:PaVoG'
        );

        let hasBVStudy = irisSecurityPermissions.some(
            (el) => el.PermissionName === 'App:BVStudy'
        );

        console.log(
            'irisSecurityPermissions--HOME--',
            irisSecurityPermissions,
            hasPavog,
            hasBVStudy
        );
        this.setState({ hasPavog: hasPavog, hasBVStudy: hasBVStudy });
    }

    render() {
        return (
            <Box>
                <ImageBackground
                    source={require('../../assets/icons/vent7.png')}
                    style={styles.bgimage}>
                    <Box
                        flex="1 1 auto"
                        width={1}
                        flexDirection="row"
                        fullHeight
                        flexWrap="wrap"
                        alignItems="flex-start"
                        justifyContent="flex-start">
                        {this.state.hasPavog && (
                            <ImageContainer
                                style={{
                                    transform: [
                                        {
                                            translateY: this.state.animVals[0].posY,
                                        },
                                    ],
                                    opacity: this.state.animVals[0].opacity,
                                }}
                                path="/PaVog"
                                source={require('../../assets/icons/fabian3.png')}
                                text={`PaVog`}
                            />
                        )}

                        {this.state.hasBVStudy && (
                            <ImageContainer
                                path="/BvStudy"
                                style={{
                                    transform: [
                                        {
                                            translateY: this.state.animVals[1].posY,
                                        },
                                    ],
                                    opacity: this.state.animVals[1].opacity,
                                }}
                                source={require('../../assets/icons/bv-final.png')}
                                text={`BvStudy`}
                            />
                        )}

                        {!this.state.hasBVStudy && !this.state.hasPavog && (
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                }}>
                                <Text style={{ fontSize: 18, marginBottom: 16 }}>
                                    You do not have access to any of the Clinical Study.
                                </Text>
                                <Text>
                                    Need help? Call 1-872-757-0400 or email
                                    ConnectIT@vyaire.com
                                </Text>
                            </View>
                        )}
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        flex="1">
                        <Box ml="auto" pr="25px">
                            <Text fontSize="15px">{pkg.expo.version}</Text>
                        </Box>
                    </Box>
                </ImageBackground>
            </Box>
        );
    }
}
//from redux store
const mapStateToProps = ({ patient, auth }) => {
    const { userRolePermissions } = auth;
    return {
        userRolePermissions,
    };
};

export default connect(mapStateToProps, {})(HomePage);

const styles = StyleSheet.create({
    image: {
        flex: 1,
        width: 150,
        height: 150,
        justifyContent: 'center',
        borderRadius: '50%',
    },
    bgimage: {
        flex: 1,
        justifyContent: 'center',
    },
});
