import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    authUserSuccess,
    authUserFail,
    authUserPermissions,
} from '../../appRedux/actions/Auth.js';
import { Auth } from 'aws-amplify';
import { AUTH_USER } from '../../constants/ActionTypes';
import { ajaxPostRequest, endpoints } from './config';

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export function* authUser(action) {
    try {
        const currentAuthenticatedUser = () =>
            Auth.currentAuthenticatedUser({ bypassCache: true });
        const user = yield call(currentAuthenticatedUser);

        const session = () => Auth.currentSession();
        const sessionData = yield call(session);

        const userAttributes = user.attributes;
        const fullName = userAttributes.email.slice(0, -11).split('.');
        const username =
            capitalize(fullName[0]) + (fullName[1] ? ' ' + capitalize(fullName[1]) : '');
        const userId =
            fullName[0].toLowerCase() +
            (fullName[1] ? '.' + fullName[1].toLowerCase() : '');
        localStorage.setItem('userId', userId);

        yield put(authUserSuccess(user));

        let idToken = sessionData.getIdToken();
        let payload = idToken.payload;
        let irisSecurityPermissions = JSON.parse(payload['IRISSecurityPermissions']);

        window.localStorage.setItem('irisPer', payload['IRISSecurityPermissions']);

        yield put(authUserPermissions(irisSecurityPermissions));

        yield* getTeamInfo({ UserId: userId });
    } catch (error) {
        console.log(error);
        yield put(authUserFail('error'));
    }
}

export function* getTeamInfo(data) {
    const url = endpoints.getUserTeam;
    try {
        let teamIds = [];
        const result = yield call(ajaxPostRequest, url, data);
        if (result.IsSuccess) {
            teamIds = result.ResultData.TeamIds;
        } else {
            //users not in CM system , users in AD group
            teamIds = [99];
        }
        localStorage.setItem('teamIds', JSON.stringify(teamIds));
    } catch (error) {
        console.log('team info not found error', error);
    }
}

const authSagas = function* rootSaga() {
    yield all([takeLatest(AUTH_USER, authUser)]);
};
export default authSagas;
