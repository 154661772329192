import React, { useCallback, useState, useEffect } from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    PixelRatio,
} from 'react-native';
import StyledTextOther from './StyledTextOther';
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';
import Flex from '../components/common/Flex';
import {
    FontAwesome,
    FontAwesome5,
    MaterialCommunityIcons,
    MaterialIcons,
} from '@expo/vector-icons';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Colors } from '../theme/Index';

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: Colors.twentyPurple,
        color: '#10206c',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

export function DockMenuCont({
    onMenuIconPress,
    onPress,
    userSecurity,
    width,
    isDefault,
}) {
    let location = useLocation();
    const [hasPavog, setPavog] = useState(false);
    const [hasPrico, setPreco] = useState(false);
    const [hasBVStudy, setBVStudy] = useState(false);
    useEffect(() => {
        let hasPavog = userSecurity.some((el) => el.PermissionName === 'App:PaVoG');
        let hasPrico = userSecurity.some((el) => el.PermissionName === 'App:Prico');
        let hasBVStudy = userSecurity.some((el) => el.PermissionName === 'App:BVStudy');

        setPavog(hasPavog);
        setPreco(hasPrico);
        setBVStudy(hasBVStudy);
    }, []);
    let props = {};
    return (
        <View style={width <= 892 && styles.overlay}>
            <View style={[styles.menuContainer]}>
                <View>
                    <Link to="/">
                        <Flex alignCenter>
                            <View>
                                <TouchableOpacity onPress={onMenuIconPress}>
                                    <Image
                                        resizeMode="contain"
                                        style={{
                                            width: 60,
                                            height: 70,
                                        }}
                                        source={require('../../assets/icons/IRISNEW1.png')}
                                    />
                                </TouchableOpacity>
                            </View>
                        </Flex>
                    </Link>
                </View>
                <View style={styles.scrollView}>
                    <View
                        style={{
                            flex: 1,
                            flexShrink: 0,
                            flexBasis: 'auto',
                            shadowColor: '#000',
                            backgroundColor: Colors.twentyPurple,
                            justifyContent: 'space-around',
                            width: '90%',
                            marginLeft: '5%',
                            borderRadius: '15px',
                        }}>
                        <View>
                            <View style={[styles.menuItem]}>
                                <CustomTooltip title="Favourites" placement="right-end">
                                    <MaterialCommunityIcons
                                        name="cards-heart"
                                        color="red"
                                        backgroundColor="#FFFFFF"
                                        style={styles.iconStyle}
                                        size={30}
                                    />
                                </CustomTooltip>
                            </View>
                        </View>

                        <View
                            style={[
                                styles.menuItem,
                                location.pathname === '/' &&
                                    styles.selectedStreamListItem,
                            ]}>
                            <Link
                                to="/"
                                style={{
                                    textDecoration: 'none',
                                    color: '#FFFFFF',
                                }}>
                                <CustomTooltip title="Setting" placement="right-end">
                                    <MaterialIcons
                                        name="settings"
                                        color="#10206C"
                                        backgroundColor="#FFFFFF"
                                        style={styles.iconStyle}
                                        size={30}
                                    />
                                </CustomTooltip>
                            </Link>
                        </View>
                        <View
                            style={[
                                styles.menuItem,
                                location.pathname === '/' &&
                                    styles.selectedStreamListItem,
                            ]}>
                            <Link
                                to="/"
                                style={{
                                    textDecoration: 'none',
                                    color: '#FFFFFF',
                                }}>
                                <CustomTooltip title="Security" placement="right-end">
                                    <MaterialCommunityIcons
                                        name="lock"
                                        color="#10206C"
                                        backgroundColor="#FFFFFF"
                                        style={styles.iconStyle}
                                        size={30}
                                    />
                                </CustomTooltip>
                            </Link>
                        </View>

                        {hasPavog && (
                            <View
                                style={[
                                    styles.menuItem,
                                    location.pathname === '/PaVog' &&
                                        styles.selectedStreamListItem,
                                ]}>
                                <Link
                                    onClick={onPress}
                                    to="/PaVog"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#FFFFFF',
                                    }}>
                                    <CustomTooltip title="PaVoG" placement="right-end">
                                        <MaterialCommunityIcons
                                            name="alpha-p-box"
                                            color="#10206C"
                                            backgroundColor="#FFFFFF"
                                            style={styles.iconStyle}
                                            size={25}
                                        />
                                    </CustomTooltip>
                                </Link>
                            </View>
                        )}
                        {hasBVStudy && (
                            <View
                                style={[
                                    styles.menuItem,
                                    location.pathname === '/BvStudy' &&
                                        styles.selectedStreamListItem,
                                ]}>
                                <Link
                                    onClick={onPress}
                                    to="/BvStudy"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#FFFFFF',
                                    }}>
                                    <CustomTooltip title="BVStudy" placement="right-end">
                                        <MaterialCommunityIcons
                                            name="alpha-b-box"
                                            color="#10206C"
                                            backgroundColor="#FFFFFF"
                                            style={styles.iconStyle}
                                            size={25}
                                        />
                                    </CustomTooltip>
                                </Link>
                            </View>
                        )}
                        {hasPrico && (
                            <View
                                style={[
                                    styles.menuItem,
                                    location.pathname === '/' &&
                                        styles.selectedStreamListItem,
                                ]}>
                                <Link
                                    onClick={onPress}
                                    to="/"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#FFFFFF',
                                    }}>
                                    <CustomTooltip title="PRECO" placement="right-end">
                                        <FontAwesome5
                                            name="book-reader"
                                            color="#10206C"
                                            backgroundColor="#FFFFFF"
                                            style={styles.iconStyle}
                                            size={25}
                                        />
                                    </CustomTooltip>
                                </Link>
                            </View>
                        )}
                    </View>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    bold: { fontWeight: 'bold' },
    overlay: {
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        position: 'absolute',
        backgroundColor: 'white',
        zIndex: 1,
        width: '100%',
    },
    menuContainer: {
        height: '100%',
        backgroundColor: '#FFFFFF',
        // width: 120,
        paddingTop: 10,
        paddingBottom: 20,
        shadowColor: '#000',
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.18,
        shadowRadius: 1.0,
        elevation: 1,
        borderRightWidth: '1px',
        borderRightColor: Colors.irisPurple,
        borderRadius: '20px',
    },
    menuItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    menuItemText: {
        fontSize: 16,
        color: Colors.royalBlue,
        fontWeight: 'normal',
    },
    menuItemTextOnHover: {
        fontSize: 16,
        fontWeight: 'normal',
        backgroundImage: 'linear-gradient(to right, #eb003b 15%, #02008a 93%)',
        backgroundSize: '100%',
    },
    menuIHItemText: {
        fontSize: 16,
        color: Colors.royalBlue,
        fontWeight: 'normal',
        paddingLeft: '2%',
    },
    menuItemDSText: {
        fontSize: 16,
        color: Colors.royalBlue,
        fontWeight: 'normal',
        paddingLeft: '2%',
    },
    menuHeaderContainer: {
        marginTop: 20,
        marginBottom: 10,
        marginHorizontal: 25,
    },
    menuItemsHeader: {
        backgroundColor: Colors.royalBlue,
        borderRadius: 25,
        color: '#FFFFFF',
        paddingVertical: 5,
        paddingHorizontal: 25,
    },
    menuItemIcon: {
        width: 30,
        height: 30,
        paddingRight: 5,
    },
    selectedStreamListItem: {
        borderLeftColor: '#b2292e', //#6ED2F6
        borderLeftWidth: 3,
        borderRadius: 2,
    },
    selectedStreamListItemText: {
        // color: '#E57230',
        color: 'red',
    },
    streamIconStyle: {
        width: 28,
        height: 28,
        paddingRight: 5,
    },
    scrollView: {
        flex: 1,
        flexBasis: 'auto',
    },
    innerItemsHeader: {
        backgroundColor: Colors.royalBlue,
        borderRadius: 25,
        color: '#FFFFFF',
        marginBottom: 10,
        paddingVertical: 5,
        paddingHorizontal: 25,
    },
    reportMenuContainer: {
        //width: 270,
        borderRightWidth: 1,
        borderRightColor: 'grey',
        marginTop: 20,
        marginBottom: 10,
    },
    iconStyle: {
        padding: 6,
        paddingVertical: 4,
    },
    logoContainer: {
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },
    vyaireLogo: {
        width: 250,
        height: 65,
    },
});
