import { Colors } from '../theme/Index';

export const ReportStatusType = {
    0: 'Unknown',
    1: 'Queued',
    2: 'InProgress',
    3: 'Completed',
    4: 'ReadyToDownload',
    5: 'Failed',
    6: 'ReportNotFound',
};

// route['address'] =
//         '/my-tasks/' +
//         SystemTypeShort[workflow.SystemTypeId] +
//         '/' +
//         WorkflowTeamTypeRouteAddressRoute[workflow.WorkflowTeamType] +
//         '/' +
//         workflow.WorkflowId;

export const getWorkflowRouteAddress = (workflow) => {
    const validRoutes = [2, 3, 4, 5, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
    const index = validRoutes.indexOf(workflow.WorkflowTeamType);
    let route = {};
    route['address'] =
        '/my-tasks/' +
        SystemTypeShort[workflow.SystemTypeId] +
        '/partners/' +
        workflow.WorkflowId +
        '/mytask';
    route['header'] = 'CREATE';

    if (index !== -1 && workflow.WorkflowTaskType === 12) {
        route['address'] = `/my-tasks/${SystemTypeShort[workflow.SystemTypeId]}/${
            WorkflowTeamTypeRouteAddressRoute[workflow.WorkflowTeamType]
        }/${workflow.WorkflowId}/update`;
        route['header'] = 'Update';
    } else if (workflow.WorkflowTaskType === 13 && workflow.WorkflowTeamType === 3) {
        route['address'] =
            `/my-tasks/` +
            SystemTypeShort[workflow.SystemTypeId] +
            `customer-global/${workflow.WorkflowId}/update`;
        route['header'] = 'Update Customer Global';
    } else if (workflow.WorkflowTaskType === 13 && workflow.WorkflowTeamType === 2) {
        route['address'] =
            `/my-tasks/` +
            SystemTypeShort[workflow.SystemTypeId] +
            `global-trade/${workflow.WorkflowId}/update`;
        route['header'] = 'Update Customer Global';
    } else if (
        index !== -1 &&
        (workflow.WorkflowTaskType === 14 || workflow.WorkflowTaskType === 22)
    ) {
        route['address'] = `/my-tasks/${SystemTypeShort[workflow.SystemTypeId]}/${
            WorkflowTeamTypeRouteAddressRoute[workflow.WorkflowTeamType]
        }/${workflow.WorkflowId}/extend-salesorg`;
        route['header'] = 'Extend to New Sales Org';
    } else if (index !== -1 && workflow.WorkflowTaskType === 15) {
        route['address'] = `/my-tasks/${SystemTypeShort[workflow.SystemTypeId]}/${
            WorkflowTeamTypeRouteAddressRoute[workflow.WorkflowTeamType]
        }/${workflow.WorkflowId}/block-unblock`;
        route['header'] = 'Block - UnBlock';
    } else if (workflow.WorkflowTaskType === 20 && workflow.WorkflowTeamType === 3) {
        route['address'] =
            `/my-tasks/` +
            SystemTypeShort[workflow.SystemTypeId] +
            `customer-master/salesforce/${workflow.WorkflowId}`;
        route['header'] = 'Create ~ SalesForce';
    } else if (workflow.WorkflowTaskType === 21) {
        route['address'] =
            `/my-tasks/` +
            SystemTypeShort[workflow.SystemTypeId] +
            `customer-master/${workflow.WorkflowId}/other-request`;
        route['header'] = 'Other';
    } else if (workflow.WorkflowTaskType === 23) {
        route['address'] =
            `/my-tasks/` +
            SystemTypeShort[workflow.SystemTypeId] +
            `extend/release/${workflow.WorkflowId}`;
    }
    return route;
};
