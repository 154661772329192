import Amplify, { Auth, Hub, Cache } from 'aws-amplify';
import React, { useState, Component } from 'react';
import {
    StyleSheet,
    View,
    Image,
    Animated,
    AsyncStorage,
    ActivityIndicator,
} from 'react-native';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ThemeProvider } from 'styled-components/native';
import { authUser } from './appRedux/actions/Auth';
import Menu from './components/Menu';
import PageLoading from './components/PageLoading';
import { PrivateRoute } from './components/PrivateRoute';
import Header from './components/header';
import Login from './containers/Login.jsx';
import config from './containers/amplify-config';
import Routes from './navigation/Routes';
import { Router, Route, Switch, Redirect, Link } from './navigation/router';
import { theme } from './theme/Index';
import { flexDirection } from 'styled-system';

Amplify.configure(config);

export interface Props {
    loggedIn: boolean;
    setCurrentUser(boolean, any): any;
    authUser(): any;
    loading: boolean;
}
export interface State {}

const ProppedRoute = ({ render: C, props: childProps, ...rest }) => (
    <Route {...rest} render={(rProps) => <C {...rProps} {...childProps} />} />
);

const MainRoutes = ({ childProps }) => (
    <Switch>
        <ProppedRoute exact path="/login" render={Login} props={childProps} />
        <PrivateRoute path="/" render={App} props={childProps} />
    </Switch>
);

export const MenuContext = React.createContext({
    isToggled: false,
    toggleMenu: () => {},
});

class App extends Component {
    toggle = (value = null) => {
        const isBoolean = value && typeof value === 'boolean';
        this.setState((state) => ({
            isToggled: isBoolean ? value : !state.isToggled,
        }));
    };

    constructor(props) {
        super(props);

        this.toggleMenu = () => {
            this.setState((state) => ({
                isToggled: !state.isToggled,
            }));
        };

        this.state = {
            isToggled: false,
            toggleMenu: this.toggle,
            redirect: false,
        };
    }

    componentDidMount() {
        const params = window.location.pathname;

        const search = window.location.search;

        const localCheck = localStorage.getItem('SFDCData');
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.location !== prevProps.location &&
            this.state.isToggled === true &&
            this.props.location.pathname !== '/search'
        ) {
            this.toggle(false);
        }
    }

    render() {
        const { isToggled, redirect } = this.state;
        const { match } = this.props;
        console.log(match);
        return (
            <View style={styles.container}>
                <MenuContext.Provider value={this.state}>
                    {this.props.location.pathname !== '/' && (
                        <View
                            style={[
                                isToggled ? { flex: 2.5 } : { flex: 1 },
                                { zIndex: 3 },
                            ]}>
                            <Menu
                                onMenuIconPress={() => this.toggle(!isToggled)}
                                style={{
                                    justifyContent: 'space-around',
                                    position: 'absolute',
                                    zIndex: 1,
                                    width: '25%',
                                }}
                                isToggled={isToggled}
                            />
                        </View>
                    )}
                    <View
                        style={[
                            isToggled ? { flex: 12 } : { flex: 18 },
                            { height: '100vh' },
                        ]}>
                        <View
                            style={{
                                flexDirection: 'column',
                                flexBasis: 'auto',
                                flexGrow: 'inherit',
                            }}>
                            <View style={{ flex: 1 }}>
                                <Header
                                    onMenuIconPress={() => this.toggle(!isToggled)}
                                    style={{ position: 'absolute', zIndex: 1 }}
                                    currentUser={this.props.currentUser}
                                    onLogout={() => {
                                        Auth.signOut()
                                            .then(async () => {
                                                await AsyncStorage.clear();
                                            })
                                            .catch((err) => console.log(err));
                                    }}
                                />
                            </View>
                            <View style={{ flex: 13 }}>
                                <Routes match={match} />
                                {redirect ? <Redirect to="/" /> : null}
                            </View>
                        </View>
                    </View>
                </MenuContext.Provider>
            </View>
        );
    }
}

class Root extends React.Component {
    constructor(props) {
        super(props);

        Hub.listen('auth', (data) => {
            const { payload } = data;

            if (data.payload.event === 'signIn') {
                this.props.authUser();
            }
        });

        this.state = {
            fontLoaded: false,
            redirect: false,
        };

        this._bootstrapAsync();
    }

    // eslint-disable-next-line no-underscore-dangle
    async _bootstrapAsync() {
        const federatedInfo = await Cache.getItem('federatedInfo');
        this.props.authUser();
    }

    async componentDidMount() {
        await Image.prefetch(require('../assets/icons/fabian3.png'));
        await Image.prefetch(require('../assets/icons/bv-final.png'));

        setTimeout(() => {
            this.setState({ fontLoaded: true });
        }, 850);

        const params = window.location.pathname;

        const search = window.location.search;

        const localCheck = localStorage.getItem('SFDCData');
    }

    render() {
        const { loggedIn, loading } = this.props;
        const { redirect } = this.state;

        const childProps = {
            isLoggedIn: loggedIn,
            onUserSignIn: this.props.authUser,
            currentUser: this.props.user,
            loading: this.props.loading,
        };

        if (!this.state.fontLoaded) return <PageLoading />;

        if (loading === true || loggedIn === null) {
            return (
                <View
                    style={{
                        flex: 1,
                        flexBasis: 'auto',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <ActivityIndicator />
                </View>
            );
        }

        return (
            <ThemeProvider theme={theme}>
                <Router hashType="noslash">
                    <Route path="/">{!loggedIn && <Redirect to="/login" />}</Route>
                    <MainRoutes childProps={childProps} />
                </Router>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.auth.loggedIn,
        user: state.auth.user,
        loggedOut: state.auth.loggedOut,
    };
};

export default connect(mapStateToProps, {
    authUser,
})(Root);

// export default Root;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100vh',
        flexDirection: 'row',
        backgroundColor: 'white',
    },
});
