import { Auth } from 'aws-amplify';
import axios from 'axios';
import * as delve from 'delve';
import {
    searchPatient,
    fetchVgStudyReport,
    generatePreSignUrl,
    refreshReportStatus,
    searchPatientBv,
    fetchBvStudyReport,
    generatePreSignUrlBv,
    refreshReportStatusBv,
} from '@env';

// export const axiosRequestInterceptor = async (config) => {
//     console.log(config);
//     const session = await Auth.currentSession();
//
//     const token = delve(session, 'idToken.jwtToken');
//     if (token) {
//         config.headers.Authorization = token;
//     }
//     return config;
// };
// axios.interceptors.request.use(axiosRequestInterceptor, (e) => Promise.reject(e));

export const endpoints = {
    searchPatient,
    fetchVgStudyReport,
    generatePreSignUrl,
    refreshReportStatus,
    searchPatientBv,
    fetchBvStudyReport,
    generatePreSignUrlBv,
    refreshReportStatusBv,
};

export const headerParams = {
    Authorization: localStorage.getItem('accessToken'),
};

export const filePartParams = {
    'Content-Type': 'multipart/form-data',
};

export const ajaxGetRequest = async (url) => {
    const userSession = await Auth.currentSession();
    // const userInfo = await Auth.currentUserInfo()
    return await axios
        .get(url, { headers: { Authorization: userSession.idToken.jwtToken } })
        .then((data) => data.data)
        .catch((error) => error);
};

export const ajaxPostRequest = async (url, data, passUserId = false) => {
    const userSession = await Auth.currentSession();
    // const userInfo = await Auth.currentUserInfo()
    let body = data;

    // console.log('jwt', userSession.idToken.jwtToken);
    return await axios
        .post(url, body, {
            headers: { Authorization: userSession.idToken.jwtToken },
        })
        .then((data) => data.data)
        .catch((error) => error);
};

export const ajaxPutFileRequest = async (url, data) => {
    // const userInfo = await Auth.currentUserInfo()
    return await axios
        .put(url, data, { headers: filePartParams })
        .then((data) => data.data)
        .catch((error) => error);
};

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatRequestorName = (name) => {
    if (name === undefined || name === null || name.length <= 0) return;

    const fullName = name.split('.');
    var username =
        capitalize(fullName[0]) + (fullName[1] ? ' ' + capitalize(fullName[1]) : '');
    return username;
};

export const normalize = (arr) => {
    const reducer = (accumulator, currentValue) => {
        accumulator[currentValue.Name] = currentValue;
        return accumulator;
    };
    return arr.reduce(reducer, {});
};
