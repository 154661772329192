import { combineReducers } from 'redux';
import authReducer from './Auth';
import toastReducer from './Toast';
import patientReducer from './PatientReducer';

const reducers = combineReducers({
    auth: authReducer,
    toasts: toastReducer,
    patient: patientReducer,
});

export default reducers;
